<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <div v-if="invoice !== null">
      <CAlert v-if="error !== null" color="danger" class="mb-3">
        {{ error }}
      </CAlert>

      <div class="ui-type-subheading text-uppercase text-gray-600">
        {{ $t("role.finance.invoiceEmitModal.recipient") }}
      </div>
      <h4>{{ invoice.i_to.name }}</h4>
      <br />

      <div class="ui-type-subheading text-uppercase text-gray-600">
        {{ $t("role.finance.invoiceEmitModal.amount") }}
      </div>
      <h4>{{ invoiceAmount() }}</h4>
      <br />

      <form action="" @submit.prevent="onSubmit" ref="form">
        <CRow>
          <CCol md="3">
            <CInput
              v-model="form.i_number"
              label="N° fattura"
              :isValid="isValid('i_number')"
              :invalidFeedback="getFieldError('i_number')"
            >
              <template #label>
                <strong>{{
                  $t("role.finance.invoiceEmitModal.invoiceNr")
                }}</strong>
                <em class="text-danger">*</em>
              </template>
            </CInput>
          </CCol>
          <CCol md="3">
            <UiDatePicker
              label="Dal"
              :date.sync="form.data_emitted"
              :isValid="isValid('valid_from')"
              :invalidFeedback="getFieldError('data_emitted')"
              :clearButton="false"
              :readonly="true"
            >
              <template #label>
                <strong>{{
                  $t("role.finance.invoiceEmitModal.invoiceDate")
                }}</strong>
                <em class="text-danger">*</em>
              </template>
            </UiDatePicker>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div class="form-group">
              <label for="msg"
                >{{ $t("role.finance.invoiceEmitModal.note") }}
                <em class="text-danger">*</em></label
              >
              <textarea
                :class="{
                  'form-control': true,
                  'is-invalid': haveFieldError('note'),
                }"
                id="message"
                v-model="form.note"
                rows="9"
              ></textarea>
              <div
                class="invalid-feedback d-block"
                v-if="haveFieldError('note')"
              >
                {{ getFieldError("note") }}
              </div>
            </div>
          </CCol>
        </CRow>
      </form>
    </div>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close">{{
        $t("role.finance.invoiceEditModal.cancelBtn")
      }}</CButton>
      <CButton
        color="primary"
        variant="outline"
        @click.prevent="invoicePreview"
        >{{ $t("role.finance.invoiceEmitModal.previewBtn") }}</CButton
      >
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >{{ $t("role.finance.invoiceEmitModal.emitBtn") }}</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import EventBus from "../../../../helpers/EventBus";
import { formatPrice } from "../../../../helpers/common";
import UiDatePicker from "../../../../common/form/UiDatePicker";

export default {
  name: "InvoicesEmitModal",

  components: {
    UiDatePicker,
  },

  data() {
    return {
      loading: false,
      error: null,
      form: {
        i_number: get(this.$store.state.invoices.emit.invoice, "i_number"),
        data_emitted: new Date(),
        note: "",
      },
    };
  },

  watch: {
    show(value) {
      // reset on open/close modal
      if (value) {
        this.reset();
      }
    },
  },

  computed: {
    title() {
      return this.$t("role.finance.invoiceEmitModal.title");
    },

    show() {
      return this.$store.state.invoices.emit.show;
    },

    invoice() {
      return this.$store.state.invoices.emit.invoice;
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.error = null;
      this.form = {
        i_number: get(this.$store.state.invoices.emit.invoice, "i_number"),
        data_emitted: new Date(),
        note: "",
      };
    },

    close() {
      this.$store.commit("invoices/closeEmitModal");
    },

    invoiceDestinatary() {
      return this.invoice.i_to.name;
    },

    invoiceAmount() {
      const currencySymbol = this.$store.getters["connections/currencySymbol"];
      return formatPrice(this.invoice.price, currencySymbol).format();
    },

    onSubmit() {
      const roleId = this.$store.state.role.id;
      const invoiceId = this.invoice.id;

      this.error = null;
      this.loading = true;
      this.$store
        .dispatch("invoices/emitInvoice", { roleId, invoiceId, ...this.form })
        .then(() => {
          this.close();
          EventBus.$emit("invoices:refresh");
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    isValid(key) {
      const s =
        get(this.error, key, undefined) !== undefined ? false : undefined;
      return s;
    },

    haveFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null) !== null;
    },

    getFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null);
    },

    invoicePreview() {
      const roleId = this.$store.state.role.id;
      const invoiceId = this.invoice.id;
      const { preview_pdf } = this.invoice;

      this.$store
        .dispatch("invoices/saveInvoice", { roleId, invoiceId, ...this.form })
        .then(() => {
          window.open(
            `${window.env.LOONITY_BASE_API_URL}${preview_pdf}`,
            "_blank"
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
